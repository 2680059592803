<!-- 病患管理 -->
<template>
	<div class="doctor_management">
	<el-row class="bread_crumbs">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item :to="{ path: '/PatientManagement' }">病患管理</el-breadcrumb-item>
			<el-breadcrumb-item :to="{ path: '/PatientManagement' }">病患信息</el-breadcrumb-item>
			<el-breadcrumb-item >病患信息列表</el-breadcrumb-item>
		</el-breadcrumb>
	</el-row>
	<div class="public_bg">
		<el-row>
			<el-col :span="24">
				<div class="grid-content">
					<template>
						<!-- <div class="form-style">
							<el-form ref="formInline" :inline="true" :model="formInline" class="demo-form-inline">
								<el-form-item label="病历号" prop="medicalNo">
									<el-input clearable v-model="formInline.medicalNo " placeholder="请输入病历号"></el-input>
								</el-form-item>
								<el-form-item label="姓名" prop="fullName">
									<el-input clearable v-model="formInline.fullName" placeholder="请输入姓名"></el-input>
								</el-form-item>
								<el-form-item label="植入日期">
									<el-col :span="11">
										<el-form-item prop="implantationDateBegin">
											<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" clearable type="datetime" placeholder="请选择植入开始日期" v-model="formInline.implantationDateBegin"
											 style="width: 100%;"></el-date-picker>
										</el-form-item>
									</el-col>
									<el-col class="line" :span="1">~</el-col>
									<el-col :span="11">
										<el-form-item prop="implantationDateEnd">
											<el-date-picker value-format="yyyy-MM-dd HH:mm:ss" clearable type="datetime" placeholder="请选择植入结束日期" v-model="formInline.implantationDateEnd"
											 style="width: 100%;"></el-date-picker>
										</el-form-item>
									</el-col>
								</el-form-item>
								<el-form-item label="产品厂商及型号" prop="ManufacturerModel">
									<el-cascader  v-model="formInline.ManufacturerModel" clearable style="width:100%" placeholder="请选择" filterable
									 :props="{ value: 'no', label: 'label'}" :options="ManufacturerModelOptions">
									</el-cascader>
								</el-form-item>
								<el-form-item label="产品序列号" prop="productSequenceNumber">
									<el-input clearable v-model="formInline.productSequenceNumber" placeholder="请输入产品序列号"></el-input>
								</el-form-item>
								<el-form-item label="所属医院" prop="value6">
									<el-select clearable filterable ref="selection" style="width:100%"  v-model="formInline.hospitalId" placeholder="请选择">
										<el-option
										  v-for="item in HospitalOptions"
										  :key="item.no"
										  :label="item.label"
										  :value="item.no">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item>
									<el-button v-if="$store.state.outerbtns.includes('BHXXADD')"  type="primary" @click="newAdd">新增</el-button>
									<el-button type="primary" @click="querys">查询</el-button>
									<el-button @click="resetForm('formInline')">重置</el-button>
								</el-form-item>
							</el-form>
						</div> -->
						<div class="tableMargin">
							<el-table :height="Height" stripe @selection-change="handleSelectionChange" ref="multipleTable" :header-cell-style="{background:'#FAFAFA'}"
							 :data="PatientsPageData" style="width: 100%">
								<el-table-column width="50px" align="left" prop="num" label="序号">
								</el-table-column>
								<el-table-column align="left" prop="fullName" label="姓名">
								</el-table-column>
								<el-table-column align="left" prop="medicalNo" label="病历号">
								</el-table-column>
								<el-table-column align="left" prop="productSequenceNumber" label="产品序列号">
								</el-table-column>
								<el-table-column align="left" prop="manufacturerIdName" label="厂商">
								</el-table-column>
								<el-table-column align="left" prop="manufacturerProductIdName" label="型号">
								</el-table-column>
								<el-table-column align="left" prop="implantationDate" label="植入日期">
								</el-table-column>
								<!--<el-table-column align="center" prop="firstConsultationDate" label="首日随访日期">
								</el-table-column>-->
								<el-table-column align="left" prop="creationDate" label="创建日期">
								</el-table-column>
								<!-- <el-table-column align="left" prop="specialDataLabel" label="二次AI识别">
								</el-table-column> -->
								<el-table-column align="left" label="操作">
									<template slot-scope="scope">
										<el-button type="text" size="small"  @click="editorPatien(scope.row.dataCode,scope.row.businessStatus)">
											{{scope.row.businessStatus=="1" ? "编辑" : "查看"}}
										</el-button>
										<el-button type="text" size="small"  @click="checkRecordS(scope.row)">随访记录</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<v-paging v-on:childPage="pages" :total=total></v-paging>
					</template>
				</div>
			</el-col>
		</el-row>
		<!--新增模态框  -->
		<el-dialog title="提示" :visible.sync="DialogChangeVisible" width="40%">
			<el-form :model="ruleFormChange" :rules="rulesDelete" ref="ruleFormChange" label-width="120px" class="demo-ruleForm">
				<el-form-item label="医院科室医生:" prop="ModelList">
					<el-cascader  ref="refSubCat"
						v-model="ruleFormChange.ModelList" clearable style="width:100%" placeholder="请选择" filterable
						:props="{ value: 'no', label: 'label'}" :options="CurrencyAllOptions">
					</el-cascader>
				</el-form-item>
				<span style="color:red;margin-left:120px" >请谨慎选择</span>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="DialogChangeVisible = false">取 消</el-button>
				<el-button type="primary" @click="DialogChangeVisibleSave">确 认</el-button>
			</span>
		</el-dialog>
		</div>
	</div>
</template>

<script>
	import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		methods: {
			//查询医院科室医生级联
			async QueryCurrencyAll(){
				let obj={
					menuId: this.menuCode,
				}
			    let res = await this.$Http.CurrencyAll(obj);
				if (res.code == '200') {
					this.CurrencyAllOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//厂商型号
			async QueryManufacturerModel(){
				let obj={
					menuId: this.menuCode,
				}
			    let res = await this.$Http.ManufacturerModel(obj);
				if (res.code == '200') {
					this.ManufacturerModelOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//医院接口
			async QueryHospital(){
				let obj={
					menuId: this.menuCode,
				}
			    let res = await this.$Http.Hospital(obj);
				if (res.code == '200') {
					this.HospitalOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			
			//分页接口
			async QueryPatientsQueryPage(current = 1, size = 10) {
				let info = {
					current: current,
					size: size,
					manufacturerId:this.formInline.ManufacturerModel[0],
					manufacturerProductId:this.formInline.ManufacturerModel[1],
					medicalNo:this.formInline.medicalNo,
					fullName:this.formInline.fullName,
					implantationDateBegin:this.formInline.implantationDateBegin,
					implantationDateEnd:this.formInline.implantationDateEnd,
					productSequenceNumber:this.formInline.productSequenceNumber,
					menuId: this.menuCode,
					medicalNo:this.medicalNo,
					hospitalId:this.hospitalId
				}
				let res = await this.$Http.PatientsQueryPageInfo(info)
				if (res.code == '200') {
					this.PatientsPageData = res.data.data;
					this.total = res.data.total;
				} else {
					this.PatientsPageData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			},
			//编辑
			editorPatien(dataCode,businessStatus) {
				this.$router.push({
					path: '/EditorPatien',
					query: {
						dataCode:dataCode,
						businessStatus:businessStatus
					}
				})
			},
			//查看随访记录
			checkRecordS(item){
				let medicalRecord=item;
				localStorage.setItem("medicalData", JSON.stringify(item));
				this.$router.push({
					path: '/CheckRecordNew',
					query: {
						medicalNo:item.medicalNo
					}
				})
			},
			//分页组件
			pages(current, size) {
                this.QueryPatientsQueryPage(current, size);
			},
			//保存
			DialogChangeVisibleSave(){
                 this.$refs["ruleFormChange"].validate((valid) => {
					if (valid) {
						let refSubCat={
							hospitalId: this.ruleFormChange.ModelList[0],
							departmentsId: this.ruleFormChange.ModelList[1],
							userId: this.ruleFormChange.ModelList[2],
							hospitalName: this.$refs["refSubCat"].getCheckedNodes()[0].pathLabels[0],
							departmentsName: this.$refs["refSubCat"].getCheckedNodes()[0].pathLabels[1],
							userName: this.$refs["refSubCat"].getCheckedNodes()[0].pathLabels[2],
						}
						localStorage.setItem("DialogList",JSON.stringify(refSubCat));
						localStorage.setItem("fileFile", "2")
						this.$router.push({
							path: '/NewAddPatient'
						})
					} else {
						return false;
					}
				});
			},
			//新增
			newAdd() {
				let types=localStorage.getItem("type");
				if(types=="200030"){
					this.DialogChangeVisible=true;
				}else{
					let refSubCat={};
					localStorage.setItem("DialogList",JSON.stringify(refSubCat));
                   localStorage.setItem("fileFile", "2")
				   this.$router.push({
						path: '/NewAddPatient'
					})
				}
				
			},
			// 搜索
			querys() {
                this.QueryPatientsQueryPage();
			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 选中事件
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		},
		data() {
			return {
				Height: document.documentElement.clientHeight - 200,
				menuCode:"",
				medicalNo:'',
				hospitalId:'',
				CurrencyAllOptions:[],
				ruleFormChange:{},
				rulesDelete:{
					ModelList: [
						{ required: true, message: '请选择医院科室医生', trigger: 'change' }
					]
				},
				DialogChangeVisible:false,
				//menuCode: JSON.parse(localStorage.getItem('menuList'))[this.$route.name],
				//厂商型号
				ManufacturerModelOptions:[],
				//医院
				HospitalOptions:[],
				// 选中数据
				multipleTable: [],
				//form表单查询
				formInline: {
					ManufacturerModel:[]
				},
				//table列表
				PatientsPageData: [],
				//总条数
				total:0,
			}
		},
		created() {},
		mounted() {
			this.medicalNo = this.$route.query.medicalNo;
			this.menuCode = this.$route.query.menuCode;
			this.hospitalId= this.$route.query.hospitalId;
			//厂商型号
			this.QueryManufacturerModel();
			//医院
			this.QueryHospital();
			//分页
			this.QueryPatientsQueryPage();
			//查询医院科室医生级联
			this.QueryCurrencyAll();
		}
	}
</script>

<style scoped>
</style>
